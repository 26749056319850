import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

export const ButtonStyles = {
  baseStyle: () => ({
    padding: 0,
    paddingX: 0,
    paddingY: 0,
    paddingLeft: `${custom.font.lg}`,
    paddingRight: `${custom.font.lg}`,
    borderRadius: '24px',
    fontWeight: 700,
    letterSpacing: '-0.4px',
    _focus: {
      boxShadow: 'none',
    },

    _disabled: {
      opacity: 1,
      pointerEvents: 'none',
    },
  }),
  sizes: {
    xs: () => ({
      minHeight: '24px',
      paddingLeft: `${custom.space.md}`,
      paddingRight: `${custom.space.md}`,
      fontSize: `${custom.font.xs}`,
    }),
    sm: () => ({
      minHeight: '32px',
      paddingLeft: `${custom.space.lg}`,
      paddingRight: `${custom.space.lg}`,
      fontSize: `${custom.font.sm}`,
      lineHeight: 1.5,
    }),
    md: () => ({
      minHeight: '40px',
      paddingLeft: `${custom.space.lgx}`,
      paddingRight: `${custom.space.lgx}`,
      fontSize: `${custom.font.md}`,
      lineHeight: 1.5,
    }),
    lg: () => ({
      minHeight: '48px',
      paddingLeft: `${custom.space.lgx}`,
      paddingRight: `${custom.space.lgx}`,
      fontSize: `${custom.font.md}`,
    }),
  },
  variants: {
    primary: () => ({
      bg: 'brand.gray900',
      color: 'brand.white',
      _disabled: {
        bg: 'brand.gray200',
      },
      _hover: {
        bg: 'brand.gray700',
      },
      _active: {
        bg: 'brand.gray900',
      },
    }),
    primaryEdit: () => ({
      bg: 'transparent',
      color: 'brand.primary700',
    }),
    primaryLanding: () => ({
      bg: 'brand.gray900',
      color: 'brand.white',
    }),
    primaryOutlined: () => ({
      bg: 'transparent',
      border: `2px solid ${colors.brand.gray900}`,

      color: 'brand.gray900',
      _disabled: {
        border: `2px solid ${colors.brand.gray500}`,
        color: 'brand.gray500',
      },
      _hover: {
        border: `2px solid ${colors.brand.gray700}`,
      },
      _active: {
        border: `2px solid ${colors.brand.gray900}`,
      },
    }),
    primaryTrial: () => ({
      bg: 'transparent',
      border: '2px solid white',

      color: 'white',
      _hover: {
        border: '2px solid #E1E6EC',
      },
    }),
    primaryReversedLanding: () => ({
      bg: 'brand.white',
      color: 'brand.gray900',
    }),
    primaryReversed: () => ({
      bg: 'brand.white',
      color: 'brand.gray900',
      _disabled: {
        bg: 'brand.gray50',
        color: 'brand.gray500',
      },
      _hover: {
        bg: 'brand.gray25',
      },
      _active: {
        bg: 'brand.white',
      },
    }),
    secondary: () => ({
      bg: 'brand.gray100',
      color: 'brand.gray900',

      _disabled: {
        bg: 'brand.gray50',
        color: 'brand.gray400',
      },
      _hover: {
        bg: 'brand.gray300',
      },
      _active: {
        bg: 'brand.gray100',
      },
    }),
    secondaryOutlined: () => ({
      bg: 'transparent',
      border: `2px solid ${colors.brand.gray300}`,
      color: 'brand.gray700',

      _disabled: {
        color: 'brand.gray300',
      },
      _hover: {
        border: `2px solid ${colors.brand.gray400}`,
        color: 'brand.gray900',
      },
      _active: {
        border: `2px solid ${colors.brand.gray400}`,
        color: 'brand.gray900',
      },
    }),
    ftue: () => ({
      bg: 'transparent',
      border: `1px solid ${colors.brand.gray50}`,
      color: 'brand.gray900',
      fontSize: '12px',
      fontWeight: 800,
      boxShadow: '0px 0px 16px 0px #D0D2D84D',
      _hover: {
        border: `1px solid ${colors.brand.gray100}`,
      },
      _active: {
        border: `1px solid ${colors.brand.gray50}`,
      },
    }),
    danger: () => ({
      bg: 'brand.error800',
      color: 'brand.white',

      _disabled: {
        bg: 'brand.error50',
        color: 'brand.error200',
      },
      _hover: {
        bg: 'brand.error600',
      },
      _active: {
        bg: 'brand.error800',
      },
    }),
    purple: () => ({
      bg: '#7B52E0',
      color: 'brand.white',
      _disabled: {
        bg: 'brand.gray200',
      },
      _hover: {
        bg: '#9D78FF',
      },
      _active: {
        bg: '#7B52E0',
      },
    }),

    dangerOutlined: () => ({
      bg: 'transparent',
      border: `2px solid ${colors.brand.error800}`,
      color: 'brand.error800',

      _disabled: {
        // bg: 'brand.white',
        border: `2px solid ${colors.brand.error100}`,
        color: 'brand.error100',
      },
      _hover: {
        border: `2px solid ${colors.brand.error500}`,
        color: 'brand.error500',
      },
      _active: {
        border: `2px solid ${colors.brand.error800}`,
        color: 'brand.error800',
      },
    }),
    dangerReversed: () => ({
      bg: 'transparent',
      border: `2px solid ${colors.brand.white}`,
      color: 'brand.white',

      _disabled: {
        bg: 'transparent',
        border: `2px solid ${colors.brand.gray200}`,
        color: 'brand.gray200',
      },
      _hover: {
        border: `2px solid ${colors.brand.gray200}`,
        color: 'brand.gray200',
      },
      _active: {
        border: `2px solid ${colors.brand.white}`,
        color: 'brand.white',
      },
    }),
    corePlan: () => ({
      bg: 'brand.success800',
      color: 'brand.white',
    }),
    premiumPlan: () => ({
      bg: 'brand.blue800',
      color: 'brand.white',
    }),
    plain: () => ({
      bg: 'transparent',
      color: 'brand.gray600',
    }),
    tertiary: () => ({
      bg: 'white',
      border: `2px solid ${colors.brand.gray50}`,
      color: 'brand.gray700',
      boxShadow: '0px 4px 12px 0px #4E4E4E1F',
      _disabled: {
        bg: 'white',
        border: `2px solid ${colors.brand.gray300}`,
        color: 'brand.gray700',
      },
      _hover: {
        bg: 'brand.gray10',
        border: `2px solid ${colors.brand.gray50}`,
        color: 'brand.gray700',
        '& img': {
          filter:
            'brightness(0) saturate(100%) invert(45%) sepia(9%) saturate(254%) hue-rotate(173deg) brightness(90%) contrast(93%)',
        },
      },
      _active: {
        border: `2px solid ${colors.brand.gray50}`,
        color: 'brand.gray700',
      },
    }),
    ssoPrimary: () => ({
      bg: 'white',
      border: `2px solid ${colors.brand.gray200}`,
      color: 'brand.gray900',
      maxWidth: '258px',
      width: '100%',
      _disabled: {
        bg: 'white',
        border: `2px solid ${colors.brand.gray300}`,
        color: 'brand.gray700',
      },
      _hover: {
        bg: 'brand.gray10',
        border: `2px solid ${colors.brand.gray50}`,
        color: 'brand.gray700',
      },
    }),
    link: () => ({
      padding: '4px 0 !important',
      border: 'none',
      fontFamily: 'GothamRounded',
      color: 'brand.primary700',
      _disabled: {
        bg: 'transparent',
        border: 'none',
        color: 'brand.primary300',
      },
      _hover: {
        color: 'brand.primary500',
        textDecoration: 'none',
      },
      _active: {
        color: 'brand.primary500',
      },

      '&:hover:not(disabled) img[color="primary700"]': {
        filter:
          'invert(79%) sepia(87%) saturate(6163%) hue-rotate(317deg) brightness(96%) contrast(91%)',
      },
    }),
    linkSecondary: () => ({
      padding: '4px 0 !important',
      border: 'none',
      fontFamily: 'GothamRounded',
      color: 'brand.gray600',
      _disabled: {
        bg: 'transparent',
        border: 'none',
        color: 'brand.gray400',
      },
      _hover: {
        color: 'brand.gray700',
        textDecoration: 'none',
      },
      _active: {
        color: 'brand.gray600',
      },
    }),
    blueOutlined: () => ({
      bg: 'transparent',
      border: `1px solid ${colors.brand.blue600}`,
      color: 'brand.gray900',
      fontWeight: 800,
      outlineOffset: 0,
      _disabled: {
        color: 'brand.gray300',
      },
      _hover: {
        outline: `1px solid ${colors.brand.blue600}`,
        color: 'brand.gray900',
      },
      _active: {
        border: `2px solid ${colors.brand.blue600}`,
        color: 'brand.gray900',
      },
    }),
  },

  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
